import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Layout from '../../common/components/Layout';
import { Markdown } from '../../common/components/Markdown';
import { getProject } from '../../common/services/project';

const styleSheet = {
  paper: {
    padding: '16px',
  },
};

const pagesContext = {
  index: {
    path: '',
    label: 'General',
    doc: 'footdata.md',
    locales: ['fr', 'es', 'de', 'en'],
  },
  people: {
    path: 'people',
    label: 'People',
    doc: 'people.md',
    locales: ['fr', 'es', 'de', 'en']
  },
  contracts: {
    path: 'contracts',
    label: 'Contracts',
    doc: 'contracts.md',
    locales: ['fr', 'es', 'de']
  },
  match_commentary: {
    path: 'match_commentary',
    label: 'Match commentaries',
    doc: 'match_commentary.md',
    locales: ['fr', 'es', 'de', 'en'],
  },
  partners: {
    path: 'partners',
    label: 'Partners',
    doc: 'partners.md',
    locales: ['fr', 'es', 'de'],
  },
  bookmakers: {
    path: 'bookmakers',
    label: 'Bookmakers',
    doc: 'bookmakers.md',
    locales: ['fr'],
  },
  surveys: {
    path: 'surveys',
    label: 'Surveys',
    doc: 'surveys.md',
    locales: ['fr', 'es', 'de'],
  },
  quizs: {
    path: 'quizs',
    label: 'Quizs',
    doc: 'quizs.md',
    locales: ['fr', 'es', 'de'],
  },
  pronostics: {
    path: 'pronostics',
    label: 'Pronostics',
    doc: 'pronostics.md',
    locales: ['fr', 'es', 'de'],
  },
  user_player_ratings: {
    path: 'user_player_ratings',
    label: 'User player ratings',
    doc: 'user_player_ratings.md',
    locales: ['fr', 'es', 'de'],
  },
  user_lineup_surveys: {
    path: 'user_lineup_surveys',
    label: 'User lineup surveys',
    doc: 'user_lineup_surveys.md',
    locales: ['fr', 'es', 'de'],
  },
  squad_lineup_surveys: {
    path: 'squad_lineup_surveys',
    label: 'Squad lineup surveys',
    doc: 'squad_lineup_surveys.md',
    locales: ['fr', 'es', 'de'],
  }
};

// @ts-ignore
@withStyles(styleSheet)
export class DocumentationPages extends React.Component<any> {
  public displayMarkdown = () => {
    const currentPathname = this.props.location.pathname.split('/').pop();
    const currentIndex = currentPathname === '' ? 'index' : currentPathname;

    if (pagesContext[currentIndex] && pagesContext[currentIndex].locales.includes(getProject().lang)) {
      return <Markdown key={currentIndex} filePath={'docs/footdata/' + getProject().lang + '/' + pagesContext[currentIndex].doc} />;
    }

    return <Redirect to={`${this.props.match.url}/`} />;
  };

  public render() {
    const { match, location, classes } = this.props;
    const baseHref = `${getProject().url}/documentation`;

    return (
      <Layout
        id="guide"
        main={
          <Paper elevation={2} className={classes.paper}>
            <Route exact path={`${match.path}/person_images`}/>
            <Route exact path={`${match.path}/match_commentary`}/>
            <Route exact path={`${match.path}/partners`}/>
            <Route exact path={`${match.path}/surveys`}/>
            <Route exact path={`${match.path}/quizs`}/>
            <Route exact path={`${match.path}/pronostics`}/>
            <Route path={match.path} render={this.displayMarkdown} />
          </Paper>
        }
        right={
          <Paper elevation={2}>
            <List component="nav">
              {Object.keys(pagesContext).map(key => {
                const href = `${baseHref}/${pagesContext[key].path}`;
                const id = pagesContext[key].label;
                const disabled = href === location.pathname;

                if (pagesContext[key].locales.includes(getProject().lang)) {
                  return (
                      <ListItem
                          key={id}
                          button
                          component="a"
                          href={`#${href}`}
                          disabled={disabled}
                      >
                        <ListItemText primary={pagesContext[key].label}/>
                      </ListItem>
                  );
                }
                return;
              })}
            </List>
          </Paper>
        }
      />
    );
  }
}
